
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { GapInterface } from '@/store/types/Analysis/GapAnalitic'
import ProgressBar from '@/components/ProgressBar/Index.vue'
import OverlayBackground from '@/components/OverlayBackground/Index.vue'
import SearchBar from '@/components/SearchBar/Index.vue'
import Unauthorized from '@/components/Unauthorized/index.vue';
import router from '@/router'
@Component({
  components: {
    ProgressBar,
    OverlayBackground,
    Unauthorized,
  },
  head: {
    script: [{ type: 'application/javascript', src: '../js/analytics.js' }],
  },
  computed: {
    ...mapGetters('GapModule', ['getAdvancedGap']),
    UnauthorizedRole() {
      const role = this.$store.state.AuthModule.AuthState.role;
      return !role.some((role: any) => role.includes("Cliente") || role.includes("Compliance") || role.includes("Implementador"));
    },
  },
})
export default class GapAnalisisComponent extends Vue {
  private loading = false
  private gapCategories: GapInterface[] = []
  private Business = this.$store.state.BusinessModule.BusinessState.businessSelected
  private versionGap = this.$store.state.GapModule.GapState.version_categories
  private renderComponent = true
  private progressData = null
  private categories = 0
  private relevated = 0
  private unauthorized = false
  public getCompanyNameSelected!: string

  @Watch('$store.state.GapModule.GapState.version_categories')
  changeVersionState(): void {
    this.getCategories()
  }

  mounted() {
    this.getCategories()
    this.$store.commit('GapModule/SET_VERSION_CATEGORIES', 0)
  }

  private goToCompliance() {
    this.$router.push('/app/compliance')
  }

  private exportGapAnalysis() {
    this.loading = true
    this.$store
      .dispatch('GapModule/exportGap', this.$route.params.id)
      .then(() => {
        this.loading = false
        this.$mixpanel.track('Boton Descargar Excel', { Empresa: this.Business.name })
      })
      .catch(() => {
        this.loading = false
      })
  }

  private goToControl(element: GapInterface) {
    this.$store.commit('GapModule/SET_CONTROL_SELECTED', element)
    this.$mixpanel.track('Categoria', {
      Nombre: element.name,
      Normativa: this.$store.state.PolimorficModule.PolimorficState.framework_selected.name,
    })
    this.$emit('goControl', element.id)

    const id_category = element.id

    this.$router.push({
      name: 'controlsList',
      params: { id_category: id_category ? id_category.toString() : 'null' },
    })
  }

  private getCategories() {
    this.loading = true
    this.renderComponent = false
    this.$store
      .dispatch('GapModule/getGapCategories', this.$route.params.id)
      .then((data) => { 
        this.gapCategories = data.data.gap_analysis_categories
        this.progressData = data.data.totals
        this.categories = data.data.totals.categories
        this.$store.commit('GapModule/SET_GAP_CATEGORIES', data.data.gap_analysis_categories)
        this.$store.commit('SearchModule/SET_SEARCH_RESULTS', data.data.gap_analysis_categories)

        let relievedCategories = 0
        this.gapCategories.forEach((element) => {
          if (element.status === 'relieved') relievedCategories++
        })
        this.relevated = relievedCategories

        this.$nextTick().then(() => {
          // Add the component back in
          this.renderComponent = true
        })
        this.unauthorized = false;
        this.loading = false
      })
      .catch((error) => {
        if (error.response.status === 403) {
          this.unauthorized = true;
        }
        if (error.response.status === 401) {
          router.push('/')
          location.reload()
        }
        this.loading = false
      })
  }

  // @Watch('$store.state.PolimorficModule.PolimorficState.framework_selected')
  // changeFramework (): void {
  //   this.getCategories()
  // }
  @Watch('$route.params.id')
  changeFramework(): void {
    this.getCategories()
  }
}
