
import { Component, Vue, Prop, } from 'vue-property-decorator'

import { ProgressBarInterface, } from '@/store/types/ProgressBar/Index'

@Component
export default class ProgressBar extends Vue {
  @Prop({ required: true, default: {}, }) readonly totals!: ProgressBarInterface
  @Prop({ required: false, default: true, }) readonly progress!: boolean

  private completion = 0
  private complies = 0
  private uncomplied = 0
  private partially = 0
  private inapplicable = 0

  mounted () {
    this.totals.complies
      ? (this.complies = this.formatDecimals((this.totals.complies * 100) / this.totals.controls))
      : 0
    this.totals.uncomplied
      ? (this.uncomplied = this.formatDecimals(
        (this.totals.uncomplied * 100) / this.totals.controls
      ))
      : 0
    this.totals.partially_complied
      ? (this.partially = this.formatDecimals(
        (this.totals.partially_complied * 100) / this.totals.controls
      ))
      : 0
    this.totals.inapplicable
      ? (this.inapplicable = this.formatDecimals(
        (this.totals.inapplicable * 100) / this.totals.controls
      ))
      : 0
    this.totals.controls
      ? (this.completion = this.formatDecimals(
        ((this.totals.controls - this.totals.unrelieved) * 100) / this.totals.controls
      ))
      : 0
  }

  private formatDecimals (value: number, fractionDigits = 2) {
    return Number(value.toFixed(fractionDigits).replace(/[.,](00)|0$/, ''))
  }
}
